import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { noop, Observable, Subscription, take } from 'rxjs';
import { BusinessEmailDetails } from '../../contact-forms.model';
import { ThemePalette } from '@angular/material/core';
import { ContactFormsService } from '../../services/contact-forms.service';

declare var window: any; // To prevent TypeScript errors

@Component({
  selector: 'app-business-contact-form',
  templateUrl: './business-contact-form.component.html',
  styleUrls: ['./business-contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessContactFormComponent {
  private eventsSubscription!: Subscription;
  @Output() sendMail: EventEmitter<BusinessEmailDetails> = new EventEmitter();
  formSubmitted: boolean = false;
  messageSuccess!: boolean;
  loading: boolean = false;
  spinnerColour: ThemePalette = 'accent';
  @Input() events: Observable<boolean> = new Observable<boolean>();
  @ViewChild('myform') private myform!: NgForm;

  businessContactForm = this.formBuilder.group({
    fullName: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ],
    ],
    phoneNumber: [
      '',
      [Validators.required, Validators.pattern('^[+][0-9]{11}$')],
    ],
    message: ['', [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private contactFormService: ContactFormsService
  ) {}

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((event) => {
      this.formSubmitted = true;
      event ? (this.messageSuccess = true) : (this.messageSuccess = false);
      if (event) {
        this.myform.resetForm();
      }
    });

    this.contactFormService.resetForm.pipe(take(1)).subscribe((reset) => {
      this.formSubmitted = false;
      this.loading = false;
    });
  }

  onFocus() {
    setTimeout(() => {
      !this.businessContactForm.get('phoneNumber')?.value
        ? this.businessContactForm.get('phoneNumber')?.patchValue('+27')
        : noop;
    }, 200);
  }

  submit() {
    const emailContent: BusinessEmailDetails = {
      fullName: this.businessContactForm.value.fullName!,
      phoneNumber: this.businessContactForm.value.phoneNumber!,
      email: this.businessContactForm.value.email!,
      companyName: this.businessContactForm.value.companyName!,
      message: this.businessContactForm.value.message!,
    };

    this.trackContactClick();

    if (this.businessContactForm.valid) {
      this.loading = true;
      this.sendMail.emit(emailContent);
    }
  }

  trackContactClick() {
    if (typeof window.lintrk === "function") {
      window.lintrk('track', { conversion_id: 21352393 });
      console.log("Event sent");
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.eventsSubscription.unsubscribe();
  }
}
